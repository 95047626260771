import React from 'react'
import Link from 'next/link'
import { cn } from '@/lib/utils'
import { Routes } from '@/v1/constants/routes'
import { PrimaryImage } from '@/v1/components/ui'
import { ParsedContent, PrimaryImageProps } from '@/types/global'

type ContentData = {
  image: PrimaryImageProps
  heading: ParsedContent
  subheading?: ParsedContent
  className?: string
}

interface Props {
  info: {
    sectionClassName?: string
    header: {
      wrClassName?: string
      titleWrClass?: string
      title: ParsedContent
      subtitle?: ParsedContent
    }
    content: {
      data: ContentData[]
      contentWrClass?: string
      descriptionWrClass?: string
      bottomContent?: ParsedContent
    }
    callToAction?: {
      text?: string
      available?: boolean
      url?: string
      className?: string
      wrClassName?: string
    }
  }
}

export function WhyUse({ info }: Props) {
  const { sectionClassName, header, content, callToAction } = info
  const { titleWrClass, title, subtitle, wrClassName } = header
  const { contentWrClass, descriptionWrClass, data, bottomContent } = content

  const showCallToAction = callToAction?.available !== false

  return (
    <>
      <section className={cn('section-gap', sectionClassName)}>
        <div className={'container'}>
          <div className={cn('w-full text-center mb-6 lg-mn:mb-[60px]', wrClassName)}>
            <div className={cn('section-title effect text-center', titleWrClass)}>
              <h2 className={cn('inline-block capitalize', title.classes)}>{title.html}</h2>
              {!!subtitle && <div className={cn(subtitle.classes)}>{subtitle.html}</div>}
            </div>
          </div>

          <div
            className={cn(
              'grid grid-cols-2 md-mn:grid-cols-3 gap-x-2 gap-y-10 md-mn:gap-y-[100px]',
              descriptionWrClass
            )}
          >
            {data.length > 0 &&
              data.map((datum, index) => (
                <div key={index} className={cn('flex flex-col text-center items-center gap-3', datum?.className)}>
                  <div>
                    <PrimaryImage image={datum.image} />
                  </div>

                  <div className={cn('space-y-1', contentWrClass)}>
                    <div
                      className={cn(
                        'text-secondary font-semibold text-lg md-mn:text-xl text-center mx-auto max-w-[200px]',
                        datum.heading.classes
                      )}
                    >
                      {datum.heading.html}
                    </div>
                    {datum.subheading && (
                      <div className={cn('text-blue-river text-sm text-center mx-auto', datum.subheading.classes)}>
                        {datum.subheading.html}
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>

          {!!bottomContent && (
            <div className={cn('text-blue-river text-sm pt-6 md-mn:pt-10', bottomContent.classes)}>
              {bottomContent.html}
            </div>
          )}

          {showCallToAction && (
            <div className={cn('flex justify-center mt-6 md-mn:mt-[60px]', callToAction?.wrClassName)}>
              <Link
                href={callToAction?.url ?? Routes.BOOK_STORAGE_FACILITY}
                target={'_blank'}
                className={cn('link-like-button', callToAction?.className)}
              >
                {callToAction?.text ?? 'Store Box Now'}
              </Link>
            </div>
          )}
        </div>
      </section>
    </>
  )
}
