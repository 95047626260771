import * as React from 'react'

export function FromStorageIcon(props) {
  return (
    <svg width={40} height={40} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M13.767 13.094h6.671c.598 0 1.08.613 1.08 1.371s-.482 1.369-1.08 1.369h-6.67c-.599 0-1.08-.611-1.08-1.369s.481-1.371 1.08-1.371M17.077 4a2.85 2.85 0 00-1.466.424l-13.49 8.38A2.38 2.38 0 001 14.82v19.765c0 .97.788 1.756 1.759 1.756h3.638a.42.42 0 00.42-.42V20.18c0-.819.616-1.48 1.378-1.48h17.817c.763 0 1.375.661 1.375 1.48v15.74c0 .232.189.42.42.42h3.641c.97 0 1.756-.786 1.756-1.756V14.819c0-.819-.422-1.58-1.119-2.014l-13.489-8.38A2.83 2.83 0 0017.076 4'
        fill='#002245'
      />
      <path
        d='M10.208 29.03c-.646 0-1.165.519-1.165 1.165v4.98c0 .647.519 1.166 1.165 1.166h4.98c.647 0 1.165-.52 1.165-1.165v-4.981a1.16 1.16 0 00-1.164-1.165h-1.746v1.766c0 .135-.15.245-.338.245h-.812c-.188 0-.342-.11-.342-.245V29.03zm8.812 0c-.648 0-1.168.519-1.168 1.165v4.98c0 .647.52 1.166 1.167 1.166h4.978c.646 0 1.166-.52 1.166-1.165v-4.981c0-.646-.52-1.165-1.166-1.165h-1.743v1.766c0 .135-.15.245-.34.245h-.812c-.188 0-.34-.11-.34-.245V29.03zm0-8.81c-.648 0-1.168.52-1.168 1.166v4.978c0 .647.52 1.167 1.167 1.167h4.978c.646 0 1.166-.52 1.166-1.167v-4.978c0-.646-.52-1.165-1.166-1.165h-1.743v1.763c0 .138-.15.247-.34.247h-.812c-.188 0-.34-.11-.34-.247V20.22z'
        fill='#1C85E8'
      />
      <path
        d='M23.506 33.727c.299-.31.377-1.03-.384-1.46-2.443-1.384-.235-3.305 1.944-3.305h7.228v1.551c-.003 1.433 1.461 1.83 2.359 1.055zm0 0c-.252.262-.605.407-1.62.14zm14.831-6.031c.564-.487.776-.968.774-1.385-.001-.418-.218-.895-.766-1.37l-4.024-3.48a.74.74 0 00-.801-.122c-.272.114-.554.389-.7.85v2.001h-9.588c-2.792-.029-4.402 1.898-4.67 4.04-.268 2.15.823 4.438 3.457 5.132.48.126.743.136.888.115a.33.33 0 00.222-.113.32.32 0 00.064-.25c-.013-.093-.074-.248-.329-.392-.674-.382-1.126-.856-1.28-1.417-.158-.579.035-1.117.367-1.535.64-.806 1.905-1.332 3.115-1.332h7.751v2.076c0 .512.242.766.505.865.29.11.679.06.988-.207z'
        fill='#ED1F23'
        stroke='#fff'
        strokeWidth={1.047}
      />
    </svg>
  )
}

export function ToStorageIcon(props) {
  return (
    <svg width={40} height={40} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M20.141 13.094h6.672c.597 0 1.08.613 1.08 1.371s-.483 1.369-1.08 1.369H20.14c-.597 0-1.08-.611-1.08-1.369s.483-1.371 1.08-1.371M23.451 4a2.85 2.85 0 00-1.466.424l-13.49 8.38a2.38 2.38 0 00-1.12 2.015v19.765c0 .97.788 1.756 1.758 1.756h3.638a.42.42 0 00.42-.42V20.18c0-.819.616-1.48 1.378-1.48h17.817c.763 0 1.376.661 1.376 1.48v15.74c0 .232.188.42.42.42h3.64c.97 0 1.757-.786 1.757-1.756V14.819a2.37 2.37 0 00-1.12-2.014l-13.488-8.38A2.83 2.83 0 0023.45 4'
        fill='#002245'
      />
      <path
        d='M16.582 29.03c-.646 0-1.165.519-1.165 1.165v4.98c0 .647.52 1.166 1.165 1.166h4.981c.646 0 1.165-.52 1.165-1.165v-4.981c0-.646-.519-1.165-1.165-1.165h-1.746v1.766c0 .135-.149.245-.337.245h-.812c-.189 0-.342-.11-.342-.245V29.03zm8.812 0c-.647 0-1.168.519-1.168 1.165v4.98c0 .647.521 1.166 1.168 1.166h4.978c.646 0 1.165-.52 1.165-1.165v-4.981c0-.646-.52-1.165-1.165-1.165h-1.744v1.766c0 .135-.15.245-.339.245h-.812c-.189 0-.34-.11-.34-.245V29.03zm-8.812-8.81c-.646 0-1.165.52-1.165 1.166v4.978c0 .647.52 1.167 1.165 1.167h4.981c.646 0 1.165-.52 1.165-1.167v-4.978c0-.646-.519-1.165-1.165-1.165h-1.746v1.763c0 .138-.149.247-.337.247h-.812c-.189 0-.342-.11-.342-.247V20.22zm8.812 0c-.647 0-1.168.52-1.168 1.166v4.978c0 .647.521 1.167 1.168 1.167h4.978c.646 0 1.165-.52 1.165-1.167v-4.978c0-.646-.52-1.165-1.165-1.165h-1.744v1.763c0 .138-.15.247-.339.247h-.812c-.189 0-.34-.11-.34-.247V20.22z'
        fill='#1C85E8'
      />
      <path
        d='M6.506 20.283c.298.31.377 1.029-.384 1.46-2.443 1.383-.235 3.305 1.944 3.305h7.228v-1.552c-.003-1.433 1.461-1.83 2.359-1.054zm0 0c-.252-.262-.605-.408-1.62-.142zm14.831 6.03c.564.487.776.968.774 1.385-.001.418-.218.895-.766 1.37l-4.024 3.481a.74.74 0 01-.801.121c-.272-.114-.554-.389-.7-.85v-2H6.231c-2.792.029-4.402-1.898-4.67-4.04-.268-2.149.823-4.438 3.457-5.131.48-.127.743-.137.888-.116a.33.33 0 01.222.113c.035.037.08.132.064.25-.013.093-.074.248-.329.392-.674.382-1.126.856-1.28 1.418-.158.578.035 1.116.367 1.535.64.806 1.905 1.331 3.115 1.331h7.751v-2.075c0-.513.242-.766.505-.866.29-.11.679-.06.988.207z'
        fill='#3AB449'
        stroke='#fff'
        strokeWidth={1.047}
      />
    </svg>
  )
}
