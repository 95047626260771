import React from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { cn } from '@/lib/utils'

interface Props extends ReactDatePickerProps {
  className?: string
  wrapperClassName?: string
}

const PrimaryDatePicker = ({ className, wrapperClassName, ...rest }: Props) => {
  return (
    <div className={cn('', wrapperClassName)}>
      <ReactDatePicker
        className={cn('z-30 w-full rounded-[4px] border-gray-400/70 placeholder:text-gray-storm/80', className, {
          'text-gray-storm/70': rest.disabled
        })}
        {...rest}
      />
    </div>
  )
}

export { PrimaryDatePicker }
