import React from 'react'
import Image from 'next/image'
import { cn } from '@/lib/utils'
import { PrimaryImageProps } from '@/types/global'

interface Props {
  image: PrimaryImageProps
  className?: string
}

export function PrimaryImage({ image, className }: Props) {
  return (
    <>
      <div className={cn('', image?.wrClassName, className)}>
        <Image src={image.src} alt={image?.alt ?? ''} className={cn('w-full h-auto', image?.className)} />
      </div>
    </>
  )
}
