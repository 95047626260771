import * as React from 'react'

function StopIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} viewBox='0 0 72 72' {...props}>
      <g fill='none' stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit={10} strokeWidth={2}>
        <path d='M44.388 14.73H27.612a1 1 0 00-.707.292L15.043 26.884a1 1 0 00-.293.707v16.776a1 1 0 00.293.707l11.862 11.862a1 1 0 00.707.293h16.776a1 1 0 00.707-.293l11.862-11.862a1 1 0 00.293-.707V27.591a1 1 0 00-.293-.707L45.095 15.022a1 1 0 00-.707-.293' />
        <path d='M39.323 39.5a2.5 2.5 0 01-2.5-2.5v-2a2.5 2.5 0 012.5-2.5h0a2.5 2.5 0 012.5 2.5v2a2.5 2.5 0 01-2.5 2.5m-13.178-5.606c-.205-.797-1.091-1.397-2.153-1.397h0c-1.214 0-2.198.783-2.198 1.75 0 .966.984 1.75 2.198 1.75l-.045.006c1.214 0 2.198.784 2.198 1.75s-.984 1.75-2.198 1.75h0c-1.062 0-1.948-.6-2.153-1.397m7.852-5.606h4m-2 0v7m14.148 0v-7h2.669c.963 0 1.743.78 1.743 1.744h0c0 .962-.78 1.743-1.743 1.743h-2.67' />
      </g>
      <path
        fill='#d22f27'
        d='M45.941 10.98H26.06a1 1 0 00-.707.292l-14.06 14.059a1 1 0 00-.292.707V45.92a1 1 0 00.293.707l14.059 14.06a1 1 0 00.707.292h19.88a1 1 0 00.707-.293l14.06-14.059A1 1 0 0061 45.92V26.038a1 1 0 00-.293-.707L46.648 11.272a1 1 0 00-.707-.293'
      />
      <path
        fill='none'
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={2}
        d='M44.388 14.73H27.612a1 1 0 00-.707.292L15.043 26.884a1 1 0 00-.293.707v16.776a1 1 0 00.293.707l11.862 11.862a1 1 0 00.707.293h16.776a1 1 0 00.707-.293l11.862-11.862a1 1 0 00.293-.707V27.591a1 1 0 00-.293-.707L45.095 15.022a1 1 0 00-.707-.293'
      />
      <path
        fill='none'
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={2}
        d='M39.323 39.5a2.5 2.5 0 01-2.5-2.5v-2a2.5 2.5 0 012.5-2.5h0a2.5 2.5 0 012.5 2.5v2a2.5 2.5 0 01-2.5 2.5m-13.178-5.606c-.205-.797-1.091-1.397-2.153-1.397h0c-1.214 0-2.198.783-2.198 1.75 0 .966.984 1.75 2.198 1.75l-.045.006c1.214 0 2.198.784 2.198 1.75s-.984 1.75-2.198 1.75h0c-1.062 0-1.948-.6-2.153-1.397m7.852-5.606h4m-2 0v7m14.148 0v-7h2.669c.963 0 1.743.78 1.743 1.744h0c0 .962-.78 1.743-1.743 1.743h-2.67'
      />
      <path
        fill='none'
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={2}
        d='M45.941 10.98H26.06a1 1 0 00-.707.292l-14.06 14.059a1 1 0 00-.292.707V45.92a1 1 0 00.293.707l14.059 14.06a1 1 0 00.707.292h19.88a1 1 0 00.707-.293l14.06-14.059A1 1 0 0061 45.92V26.038a1 1 0 00-.293-.707L46.648 11.272a1 1 0 00-.707-.293'
      />
    </svg>
  )
}

export { StopIcon }
