import * as React from 'react'

function PhoneIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <path
        fill='none'
        strokeDasharray={64}
        strokeDashoffset={64}
        d='M8 3c.5 0 2.5 4.5 2.5 5 0 1-1.5 2-2 3s.5 2 1.5 3c.394.394 2 2 3 1.5s2-2 3-2c.5 0 5 2 5 2.5 0 2-1.5 3.5-3 4s-2.5.5-4.5 0-3.5-1-6-3.5-3-4-3.5-6-.5-3 0-4.5 2-3 4-3z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
      >
        <animate fill='freeze' attributeName='stroke-dashoffset' dur='0.6s' values='64;0' />
        <animate fill='freeze' attributeName='fill-opacity' begin='0.6s' dur='0.15s' values='0;0.3' />
        <animateTransform
          attributeName='transform'
          begin='0.6s;lineMdPhoneCallTwotoneLoop0.begin+2.6s'
          dur='0.5s'
          type='rotate'
          values='0 12 12;15 12 12;0 12 12;-12 12 12;0 12 12;12 12 12;0 12 12;-15 12 12;0 12 12'
        />
      </path>
    </svg>
  )
}

export { PhoneIcon }
