import React from 'react'
import { cn } from '@/lib/utils'
import { PrimaryImage } from '@/v1/components/ui'
import { ParsedContent, PrimaryImageProps } from '@/types/global'

type ContentData = {
  image: PrimaryImageProps
  heading: ParsedContent
  subheading: ParsedContent
}

interface Props {
  info: {
    sectionClassName?: string
    header: {
      wrClassName?: string
      titleWrClass?: string
      title: ParsedContent
      subtitle: ParsedContent
    }
    content: {
      data: ContentData[]
      bottomContent?: ParsedContent
      contentWrClass?: string
      image: PrimaryImageProps
    }
  }
}

export function Benefits({ info }: Props) {
  const { sectionClassName, header, content } = info
  const { titleWrClass, title, subtitle, wrClassName } = header
  const { image, contentWrClass, data, bottomContent } = content

  return (
    <>
      <section className={cn('section-gap', sectionClassName)}>
        <div className={'container'}>
          <div className={cn('w-full text-center mb-6 lg-mn:mb-[60px]', wrClassName)}>
            <div className={cn('section-title effect text-center', titleWrClass)}>
              <h2 className={cn('capitalize inline-block', title.classes)}>{title.html}</h2>
              <p className={cn(subtitle.classes)}>{subtitle.html}</p>
            </div>
          </div>

          <div className={cn('flex justify-between items-center flex-col 2xl:flex-row lg-mn:gap-14', contentWrClass)}>
            <PrimaryImage image={image} className={'hidden lg-mn:inline-flex lg-mn:min-w-[510px]'} />
            <div>
              <div className={'space-y-5'}>
                {data.length > 0 &&
                  data.map((datum, index) => (
                    <div
                      key={index}
                      className={
                        'flex flex-col lg-mn:flex-row text-center lg-mn:text-left items-center gap-4 lg-mn:gap-6'
                      }
                    >
                      <div>
                        <PrimaryImage image={datum.image} className={'w-[80px] h-[80px]'} />
                      </div>
                      <div className={'space-y-1'}>
                        <div
                          className={cn(
                            'capitalize text-blue-river font-semibold text-lg md-mn:text-xl',
                            datum.heading.classes
                          )}
                        >
                          {datum.heading.html}
                        </div>
                        <div className={cn('text-blue-river text-sm', datum.subheading.classes)}>
                          {datum.subheading.html}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {!!bottomContent && (
                <div className={cn('text-blue-river text-sm pt-6', bottomContent.classes)}>{bottomContent.html}</div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
