import React, { HTMLAttributeAnchorTarget, HTMLAttributes } from 'react'
import Link, { LinkProps } from 'next/link'
import { cn } from '@/lib/utils'
import CircularProgress from '@mui/material/CircularProgress'

interface PrimaryLinkProps extends HTMLAttributes<HTMLAnchorElement>, LinkProps {
  className?: string
  loading?: boolean
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  children: React.ReactNode
  target?: HTMLAttributeAnchorTarget
}

export const PrimaryLink = ({
  className,
  target,
  children,
  loading,
  disabled,
  onClick,
  ...props
}: PrimaryLinkProps) => {
  const hasPermissionToClick = !disabled && !loading

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick && hasPermissionToClick) {
      return onClick(event)
    }

    event.preventDefault()
  }

  return (
    <>
      {loading && <CircularProgress size={18} className={'text-gray-400 mr-1'} />}

      <Link
        className={cn(
          'border-b-2 border-secondary text-secondary hover:text-blue-700 hover:border-blue-700 w-fit leading-none font-semibold',
          className,
          {
            'text-gray-400 border-gray-400 hover:text-gray-400 hover:border-gray-400 cursor-not-allowed':
              !hasPermissionToClick
          }
        )}
        target={target}
        onClick={onClick ? handleClick : undefined}
        {...props}
      >
        {children}
      </Link>
    </>
  )
}
