import * as React from 'react'

export function FileDownloadIcon(props) {
  return (
    <svg width={27} height={28} viewBox='0 0 27 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={19} cy={20} r={7} stroke='#000' strokeWidth={2} />
      <path d='M19 24l3-2.851-1.015-.965-1.267 1.205V17h-1.436v4.39l-1.267-1.206L16 21.15z' fill='#000' />
      <path
        d='M13.5 24.997H3.222c-.74 0-2.222.21-2.222-3.13V3.087C1 1 2.111 1 4.889 1h9.444L21 7.26V13'
        stroke='#000'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <path d='M6 10h9m-9 4h9m-9 4h6M6 6h5' stroke='#000' strokeWidth={1.5} strokeLinecap='round' />
    </svg>
  )
}
