import * as React from 'react'

export function ExpandIcon(props) {
  return (
    <svg width={30} height={30} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={15} cy={15} r={15} />
      <path
        d='M22.372 16.55c.093.088.14.191.14.307a.414.414 0 01-.14.308l-.703.67a.455.455 0 01-.646 0L15.5 12.57l-5.522 5.264a.455.455 0 01-.647 0l-.702-.67a.414.414 0 01-.141-.308c0-.116.047-.219.14-.308l6.549-6.241a.455.455 0 01.646 0l6.549 6.241z'
        fill='#676767'
      />
    </svg>
  )
}
