import * as React from 'react'

function QuestionIcon({ ...props }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} viewBox='0 0 256 256' {...props}>
      <path
        fill='currentColor'
        d='M140 180a12 12 0 11-12-12 12 12 0 0112 12M128 72c-22.06 0-40 16.15-40 36v4a8 8 0 0016 0v-4c0-11 10.77-20 24-20s24 9 24 20-10.77 20-24 20a8 8 0 00-8 8v8a8 8 0 0016 0v-.72c18.24-3.35 32-17.9 32-35.28 0-19.85-17.94-36-40-36m104 56A104 104 0 11128 24a104.11 104.11 0 01104 104m-16 0a88 88 0 10-88 88 88.1 88.1 0 0088-88'
      />
    </svg>
  )
}

export { QuestionIcon }
