import React from 'react'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

export function ListLoader(props: IContentLoaderProps) {
  const items = [0, 110, 220, 330, 440].map(function (item) {
    return (
      <React.Fragment key={item}>
        <rect x='0' y={8 + item} rx='5' ry='5' width='496' height='8' />
        <rect x='0' y={86 + item} rx='5' ry='5' width='496' height='8' />
        <rect x='0' y={8 + item} rx='5' ry='5' width='7' height='86' />
        <rect x='491' y={8 + item} rx='5' ry='5' width='6' height='86' />

        <rect x='380' y={25 + item} rx='5' ry='5' width='100' height='50' />

        <rect x='15' y={25 + item} rx='3' ry='3' width='355' height='10' />
        <rect x='15' y={45 + item} rx='3' ry='3' width='355' height='10' />
        <rect x='15' y={65 + item} rx='3' ry='3' width='355' height='10' />
      </React.Fragment>
    )
  })

  return (
    <ContentLoader width='100%' height={600} {...props}>
      {[items]}
    </ContentLoader>
  )
}
