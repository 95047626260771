import * as React from 'react'
import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/AddCircleOutline'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import MuiAccordionDetails, { AccordionDetailsProps } from '@mui/material/AccordionDetails'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'

import { ExpandIcon } from '@/v1/icons'
import { cn, parsedContent } from '@/lib/utils'

const Accordion = styled((props: AccordionProps & { mb?: number; expandedMb?: number }) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, mb = 5, expandedMb = 15 }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '3px 3px 0px 0px',
  '&:not(:last-child)': {
    marginBottom: mb,
    borderBottom: 0
  },
  '&::before': {
    display: 'none'
  },
  '&.Mui-expanded': {
    marginBottom: expandedMb
  }
}))

const AccordionSummary = styled(
  (props: AccordionSummaryProps & { bgColor?: string; expandedBgColor?: string; willRotate?: boolean }) => (
    <MuiAccordionSummary expandIcon={<ExpandIcon className={'rotate-90 delay-300'} />} {...props} />
  )
)(({ theme, bgColor = '#FFF', expandedBgColor, willRotate = false }) => ({
  backgroundColor: bgColor,
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: willRotate && 'rotate(270deg)',
    ['svg']: {
      fill: willRotate && '#fff'
    }
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  },
  '&.Mui-expanded': {
    backgroundColor: expandedBgColor
  }
}))

const AccordionDetails = styled((props: AccordionDetailsProps & { spacing?: number }) => (
  <MuiAccordionDetails {...props} />
))(({ theme, spacing = 2 }) => ({
  padding: theme.spacing(spacing),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  borderBottom: '1px solid rgba(0, 0, 0, .125)'
}))

type ChildrenType = {
  title: string
  content: string
}

type TData = {
  heading: string
  childrens?: ChildrenType[]
}

interface Props {
  info: {
    defaultExpanded: number
    childDefaultExpanded?: number
    rootSpacing?: number
    data: TData[]
  }
  children?: React.ReactNode
}

export function PrimaryAccordion({ info, children }: Props) {
  const [rootExpanded, setRootExpanded] = React.useState<number | false>(info.defaultExpanded)

  const handleChange = (panel: number) => (_: React.SyntheticEvent, newExpanded: boolean) => {
    setRootExpanded(newExpanded ? panel : false)
    setChildExpanded(info?.childDefaultExpanded ?? false)
  }

  const [childExpanded, setChildExpanded] = React.useState<number | false>(info?.childDefaultExpanded ?? false)

  const changeChildExpanded = (panel: number) => (_: React.SyntheticEvent, newExpanded: boolean) => {
    setChildExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      {info.data.map((item, index) => {
        return (
          <>
            <Accordion key={index} expanded={rootExpanded === index} onChange={handleChange(index)}>
              <AccordionSummary
                aria-controls={`parent${index}-content`}
                id={`parent${index}-header`}
                bgColor={'#C0DBFB'}
                willRotate
              >
                <p className={'text-[#464646] text-xl font-semibold'}>{item.heading}</p>
              </AccordionSummary>
              <AccordionDetails spacing={info?.rootSpacing}>
                {info.data[index]?.childrens &&
                  info.data[index]?.childrens?.map((child, childIndex) => {
                    const content = parsedContent(child.content)

                    return (
                      <>
                        <Accordion
                          key={childIndex}
                          expanded={childExpanded === childIndex}
                          onChange={changeChildExpanded(childIndex)}
                          mb={0}
                          expandedMb={0}
                        >
                          <AccordionSummary
                            id={`child${childIndex}-header`}
                            aria-controls={`child${childIndex}-content`}
                            expandedBgColor={'#F3F3F3'}
                            expandIcon={childExpanded === childIndex ? <RemoveCircleOutlineIcon /> : <ExpandMoreIcon />}
                          >
                            <p className={'text-[#0D8EE9] lg-mn:text-lg'}>{child.title}</p>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className={cn('', content.classes)}>{content.html}</div>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    )
                  })}

                {children && <>{children}</>}
              </AccordionDetails>
            </Accordion>
          </>
        )
      })}
    </>
  )
}
