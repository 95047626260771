import { ReactNode } from 'react'
import Select, { SelectProps } from '@mui/material/Select'

interface Props extends Omit<SelectProps<string, 'outlined'>, 'variant'> {
  howManyItems?: number
  children?: ReactNode
}

const PrimarySelect = ({ howManyItems = 5, children, ...rest }: Props) => {
  // Define constants for item height and padding
  const ITEM_HEIGHT = 36
  const MOBILE_ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8

  // Get the number of items to display from props
  const MENU_ITEMS = howManyItems

  return (
    <>
      <Select
        fullWidth
        displayEmpty
        size={'small'}
        MenuProps={{
          PaperProps: {
            sx: {
              minWidth: '20px',
              maxWidth: '40px',
              // Set max height based on number of items and screen size
              maxHeight: {
                xs: MOBILE_ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
                sm: ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP
              }
            }
          }
        }}
        {...rest} // Pass other props
      >
        {children} {/* Render select options */}
      </Select>
    </>
  )
}

export { PrimarySelect }
