import React, { ButtonHTMLAttributes } from 'react'
import { cn } from '@/lib/utils'
import { cva, type VariantProps } from 'class-variance-authority'
import CircularProgress from '@mui/material/CircularProgress'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-white text-black shadow hover:bg-white/90',
        outline: 'border bg-white shadow-sm',
        primary: 'btn btn-primary hover:bg-primary-orange',
        secondary: 'bg-secondary text-white shadow-sm hover:bg-secondary/80',
        link: 'min-h-0 bg-transparent text-secondary w-fit font-semibold hover:text-blue-700 underline-offset-4 underline',
        danger: 'bg-red-strong text-white hover:bg-red-strong/80',
        orange: 'btn bg-primary-orange hover:bg-primary-orange/90'
      },
      size: {
        default: 'px-4',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8',
        icon: 'h-9 w-9'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    Omit<VariantProps<typeof buttonVariants>, 'variant'> {
  loading?: boolean
  variant?: 'default' | 'outline' | 'primary' | 'secondary' | 'link' | 'danger' | 'orange'
}

const PrimaryButton = ({
  children,
  loading,
  disabled,
  onClick,
  className,
  type,
  variant,
  size,
  ...props
}: ButtonProps) => {
  const hasPermissionToClick = !disabled && !loading

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick && hasPermissionToClick) {
      return onClick(event)
    }

    if (type === 'button' || !hasPermissionToClick) {
      event.preventDefault()
    }
  }

  return (
    <>
      <button
        className={cn('bg-blue-500 min-h-[34px]', buttonVariants({ variant, size, className }), {
          'bg-gray-300 hover:bg-gray-300 text-gray-600 cursor-not-allowed': !hasPermissionToClick
        })}
        onClick={handleClick}
        {...props}
      >
        {loading && <CircularProgress size={18} className={'text-gray-600 mr-1'} />}

        {children}
      </button>
    </>
  )
}

PrimaryButton.displayName = 'Primary Button'

export { buttonVariants, PrimaryButton }
