import React from 'react'
import Link from 'next/link'
import { cn } from '@/lib/utils'
import { Routes } from '@/v1/constants/routes'
import { PrimaryImage } from '@/v1/components/ui'
import { ParsedContent, PrimaryImageProps } from '@/types/global'

type ContentData = {
  image: PrimaryImageProps
  heading: ParsedContent
  subheading?: ParsedContent
  className?: string
}

interface Props {
  info: {
    sectionClassName?: string
    header: {
      wrClassName?: string
      titleWrClass?: string
      title: ParsedContent
      subtitle?: ParsedContent
    }
    content: {
      data: ContentData[]
      contentWrClass?: string
      descriptionWrClass?: string
    }
    callToAction?: {
      url?: string
      text?: string
      className?: string
      available?: boolean
      wrClassName?: string
    }
  }
}

export function MultiGridCard({ info }: Props) {
  const { sectionClassName, header, content, callToAction } = info
  const { titleWrClass, title, subtitle, wrClassName } = header
  const { contentWrClass, descriptionWrClass, data } = content

  return (
    <>
      <section className={cn('section-gap', sectionClassName)}>
        <div className={'container'}>
          <div className={cn('w-full text-center mb-6 lg-mn:mb-[60px]', wrClassName)}>
            <div className={cn('section-title effect text-center', titleWrClass)}>
              <h2 className={cn('capitalize inline-block', title.classes)}>{title.html}</h2>
              {!!subtitle && <div className={cn(subtitle.classes)}>{subtitle.html}</div>}
            </div>
          </div>

          <div className={cn('grid grid-cols-1 md-mn:grid-cols-2 gap-5', descriptionWrClass)}>
            {data.length > 0 &&
              data.map((datum, index) => (
                <div
                  key={index}
                  className={cn('bg-white-ice flex flex-col text-center items-center gap-6', datum?.className)}
                >
                  <PrimaryImage image={datum.image} />

                  <div className={cn('w-full space-y-3 px-6 pb-6', contentWrClass)}>
                    <div
                      className={cn(
                        'capitalize text-secondary font-semibold text-lg md-mn:text-xl text-center mx-auto max-w-[280px]',
                        datum.heading.classes
                      )}
                    >
                      {datum.heading.html}
                    </div>
                    {datum.subheading && (
                      <div className={cn('text-blue-river text-sm text-center mx-auto', datum.subheading.classes)}>
                        {datum.subheading.html}
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>

          {!!callToAction?.available && (
            <div className={cn('flex justify-center mt-6 md-mn:mt-[60px]', callToAction?.wrClassName)}>
              <Link
                href={callToAction?.url ?? Routes.BOOK_STORAGE_FACILITY}
                target={'_blank'}
                className={cn('link-like-button', callToAction?.className)}
              >
                {callToAction?.text ?? 'Store Box Now'}
              </Link>
            </div>
          )}
        </div>
      </section>
    </>
  )
}
